@charset "UTF-8";
/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 5.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");
/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #4a81d4;
  --bs-indigo: #675aa9;
  --bs-purple: #6658dd;
  --bs-pink: #f672a7;
  --bs-red: #f1556c;
  --bs-orange: #fd7e14;
  --bs-yellow: #f7b84b;
  --bs-green: #1abc9c;
  --bs-teal: #02a8b5;
  --bs-cyan: #4fc6e1;
  --bs-white: #fff;
  --bs-gray: #cedeef;
  --bs-gray-dark: #f7f7f7;
  --bs-gray-100: #323a46;
  --bs-gray-200: #36404a;
  --bs-gray-300: #424e5a;
  --bs-gray-400: #5d7186;
  --bs-gray-500: #8c98a5;
  --bs-gray-600: #cedeef;
  --bs-gray-700: #dee2e6;
  --bs-gray-800: #f7f7f7;
  --bs-gray-900: #f3f7f9;
  --bs-primary: #6658dd;
  --bs-secondary: #cedeef;
  --bs-success: #1abc9c;
  --bs-info: #4fc6e1;
  --bs-warning: #f7b84b;
  --bs-danger: #f1556c;
  --bs-light: #424e5a;
  --bs-dark: #f3f7f9;
  --bs-pink: #f672a7;
  --bs-blue: #4a81d4;
  --bs-primary-rgb: 102, 88, 221;
  --bs-secondary-rgb: 206, 222, 239;
  --bs-success-rgb: 26, 188, 156;
  --bs-info-rgb: 79, 198, 225;
  --bs-warning-rgb: 247, 184, 75;
  --bs-danger-rgb: 241, 85, 108;
  --bs-light-rgb: 66, 78, 90;
  --bs-dark-rgb: 243, 247, 249;
  --bs-pink-rgb: 246, 114, 167;
  --bs-blue-rgb: 74, 129, 212;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 148, 160, 173;
  --bs-font-sans-serif: "Rubik", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Rubik, sans-serif;
  --bs-body-font-size: 0.84rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #94a0ad;
  --bs-body-bg: #303841; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1.5rem 0;
  color: #424e5a;
  background-color: currentColor;
  border: 0;
  opacity: 1; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.1; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 0.9375rem; }

h6, .h6 {
  font-size: 0.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 500; }

small, .small {
  font-size: 0.75rem; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #6658dd;
  text-decoration: none; }
  a:hover {
    color: #fff;
    text-decoration: none; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  color: #f3f7f9; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 87.5%;
  color: #f672a7;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #424e5a;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 500; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  color: #8c98a5;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.05rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.05rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: #cedeef; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #424e5a;
  border: 1px solid #424e5a;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.75rem;
  color: #cedeef; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 12px);
  padding-left: var(--bs-gutter-x, 12px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 2.25rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 2.25rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 4.5rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 4.5rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.25rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.25rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.5rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.25rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.25rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.5rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.25rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.25rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.5rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.25rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.25rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.5rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.25rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.25rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.5rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.5rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #94a0ad;
  --bs-table-striped-bg: #3b4651;
  --bs-table-active-color: #94a0ad;
  --bs-table-active-bg: rgba(0, 0, 0, 0.075);
  --bs-table-hover-color: #94a0ad;
  --bs-table-hover-bg: #3b4651;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #94a0ad;
  vertical-align: top;
  border-color: #424e5a; }
  .table > :not(caption) > * > * {
    padding: 0.85rem 0.85rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #424e5a; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #e0def8;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #cfcee7;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #d3d2eb;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #cfcee7; }

.table-secondary {
  --bs-table-bg: #f5f8fc;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #e2e6ea;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #e7eaef;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #e2e6ea; }

.table-success {
  --bs-table-bg: #d1f2eb;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #c2e0db;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #c5e5df;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #c2e0db; }

.table-info {
  --bs-table-bg: #dcf4f9;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #cbe2e8;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #d0e7ec;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #cbe2e8; }

.table-warning {
  --bs-table-bg: #fdf1db;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #e9dfcd;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #eee4d0;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #e9dfcd; }

.table-danger {
  --bs-table-bg: #fcdde2;
  --bs-table-striped-bg: #36404a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #e8cdd3;
  --bs-table-active-color: #36404a;
  --bs-table-hover-bg: #edd1d7;
  --bs-table-hover-color: #36404a;
  color: #36404a;
  border-color: #e8cdd3; }

.table-light {
  --bs-table-bg: #3e4954;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #36404a;
  --bs-table-active-bg: #515b65;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #4c5761;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #515b65; }

.table-dark {
  --bs-table-bg: #424e5a;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #36404a;
  --bs-table-active-bg: #55606b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #505b66;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #55606b; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.05rem; }

.col-form-label-sm {
  padding-top: calc(0.28rem + 1px);
  padding-bottom: calc(0.28rem + 1px);
  font-size: 0.756rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #8c98a5; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.84rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94a0ad;
  background-color: #3b4651;
  background-clip: padding-box;
  border: 1px solid #424e5a;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #94a0ad;
    background-color: #3b4651;
    border-color: #475461;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #586877;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #3b4651;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.45rem 0.9rem;
    margin: -0.45rem -0.9rem;
    margin-inline-end: 0.9rem;
    color: #94a0ad;
    background-color: #36404a;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #333d46; }
  .form-control::-webkit-file-upload-button {
    padding: 0.45rem 0.9rem;
    margin: -0.45rem -0.9rem;
    margin-inline-end: 0.9rem;
    color: #94a0ad;
    background-color: #36404a;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #333d46; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #94a0ad;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.756rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.28rem 0.8rem;
    margin: -0.28rem -0.8rem;
    margin-inline-end: 0.8rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.28rem 0.8rem;
    margin: -0.28rem -0.8rem;
    margin-inline-end: 0.8rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.05rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.9rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.45rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.2rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.2rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.45rem 2.7rem 0.45rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.84rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94a0ad;
  background-color: #3b4651;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 14px 10px;
  border: 1px solid #424e5a;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #475461;
    outline: 0;
    box-shadow: none; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.9rem;
    background-image: none; }
  .form-select:disabled {
    color: #cedeef;
    background-color: #36404a; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #94a0ad; }

.form-select-sm {
  padding-top: 0.28rem;
  padding-bottom: 0.28rem;
  padding-left: 0.8rem;
  font-size: 0.756rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.05rem; }

.form-check {
  display: block;
  min-height: 1.26rem;
  padding-left: 1.643em;
  margin-bottom: 0; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.643em; }

.form-check-input {
  width: 1.143em;
  height: 1.143em;
  margin-top: 0.1785em;
  vertical-align: top;
  background-color: #3b4651;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #5d7186;
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #475461;
    outline: 0;
    box-shadow: none; }
  .form-check-input:checked {
    background-color: #6658dd;
    border-color: #6658dd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #6658dd;
    border-color: #6658dd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235d7186'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23475461'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #303841, none; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #303841, none; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #6658dd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #d1cdf5; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #424e5a;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #6658dd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #d1cdf5; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #424e5a;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #8c98a5; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #8c98a5; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.9rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.9rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  font-size: 0.84rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94a0ad;
  text-align: center;
  white-space: nowrap;
  background-color: #36404a;
  border: 1px solid #424e5a;
  border-radius: 0.2rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.05rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.756rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.6rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #1abc9c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.756rem;
  color: #fff;
  background-color: rgba(26, 188, 156, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1abc9c;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231abc9c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #1abc9c;
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #1abc9c; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231abc9c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 2.7rem;
    background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #1abc9c;
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #1abc9c; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #1abc9c; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #1abc9c; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #f1556c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.756rem;
  color: #fff;
  background-color: rgba(241, 85, 108, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f1556c;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1556c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1556c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f1556c;
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f1556c; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1556c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1556c' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 2.7rem;
    background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #f1556c;
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f1556c; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #f1556c; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #f1556c; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #94a0ad;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.84rem;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #94a0ad; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #6658dd;
  border-color: #6658dd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #574bbc;
    border-color: #5246b1; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #574bbc;
    border-color: #5246b1;
    box-shadow: 0 0 0 0.15rem rgba(125, 113, 226, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5246b1;
    border-color: #4d42a6; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(125, 113, 226, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }

.btn-secondary {
  color: #36404a;
  background-color: #cedeef;
  border-color: #cedeef; }
  .btn-secondary:hover {
    color: #36404a;
    background-color: #d5e3f1;
    border-color: #d3e1f1; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #36404a;
    background-color: #d5e3f1;
    border-color: #d3e1f1;
    box-shadow: 0 0 0 0.15rem rgba(183, 198, 214, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #36404a;
    background-color: #d8e5f2;
    border-color: #d3e1f1; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(183, 198, 214, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #36404a;
    background-color: #cedeef;
    border-color: #cedeef; }

.btn-success {
  color: #fff;
  background-color: #1abc9c;
  border-color: #1abc9c; }
  .btn-success:hover {
    color: #fff;
    background-color: #16a085;
    border-color: #15967d; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #16a085;
    border-color: #15967d;
    box-shadow: 0 0 0 0.15rem rgba(60, 198, 171, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #15967d;
    border-color: #148d75; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(60, 198, 171, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c; }

.btn-info {
  color: #fff;
  background-color: #4fc6e1;
  border-color: #4fc6e1; }
  .btn-info:hover {
    color: #fff;
    background-color: #43a8bf;
    border-color: #3f9eb4; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fff;
    background-color: #43a8bf;
    border-color: #3f9eb4;
    box-shadow: 0 0 0 0.15rem rgba(105, 207, 230, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #3f9eb4;
    border-color: #3b95a9; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(105, 207, 230, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1; }

.btn-warning {
  color: #36404a;
  background-color: #f7b84b;
  border-color: #f7b84b; }
  .btn-warning:hover {
    color: #36404a;
    background-color: #f8c366;
    border-color: #f8bf5d; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #36404a;
    background-color: #f8c366;
    border-color: #f8bf5d;
    box-shadow: 0 0 0 0.15rem rgba(218, 166, 75, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #36404a;
    background-color: #f9c66f;
    border-color: #f8bf5d; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(218, 166, 75, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #36404a;
    background-color: #f7b84b;
    border-color: #f7b84b; }

.btn-danger {
  color: #fff;
  background-color: #f1556c;
  border-color: #f1556c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #cd485c;
    border-color: #c14456; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #cd485c;
    border-color: #c14456;
    box-shadow: 0 0 0 0.15rem rgba(243, 111, 130, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c14456;
    border-color: #b54051; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(243, 111, 130, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c; }

.btn-light {
  color: #fff;
  background-color: #424e5a;
  border-color: #424e5a; }
  .btn-light:hover {
    color: #fff;
    background-color: #38424d;
    border-color: #353e48; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #fff;
    background-color: #38424d;
    border-color: #353e48;
    box-shadow: 0 0 0 0.15rem rgba(94, 105, 115, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #fff;
    background-color: #353e48;
    border-color: #323b44; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(94, 105, 115, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #fff;
    background-color: #424e5a;
    border-color: #424e5a; }

.btn-dark {
  color: #36404a;
  background-color: #f3f7f9;
  border-color: #f3f7f9; }
  .btn-dark:hover {
    color: #36404a;
    background-color: #f5f8fa;
    border-color: #f4f8fa; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #36404a;
    background-color: #f5f8fa;
    border-color: #f4f8fa;
    box-shadow: 0 0 0 0.15rem rgba(215, 220, 223, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #36404a;
    background-color: #f5f9fa;
    border-color: #f4f8fa; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(215, 220, 223, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #36404a;
    background-color: #f3f7f9;
    border-color: #f3f7f9; }

.btn-pink {
  color: #fff;
  background-color: #f672a7;
  border-color: #f672a7; }
  .btn-pink:hover {
    color: #fff;
    background-color: #d1618e;
    border-color: #c55b86; }
  .btn-check:focus + .btn-pink, .btn-pink:focus {
    color: #fff;
    background-color: #d1618e;
    border-color: #c55b86;
    box-shadow: 0 0 0 0.15rem rgba(247, 135, 180, 0.5); }
  .btn-check:checked + .btn-pink,
  .btn-check:active + .btn-pink, .btn-pink:active, .btn-pink.active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #c55b86;
    border-color: #b9567d; }
    .btn-check:checked + .btn-pink:focus,
    .btn-check:active + .btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(247, 135, 180, 0.5); }
  .btn-pink:disabled, .btn-pink.disabled {
    color: #fff;
    background-color: #f672a7;
    border-color: #f672a7; }

.btn-blue {
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4; }
  .btn-blue:hover {
    color: #fff;
    background-color: #3f6eb4;
    border-color: #3b67aa; }
  .btn-check:focus + .btn-blue, .btn-blue:focus {
    color: #fff;
    background-color: #3f6eb4;
    border-color: #3b67aa;
    box-shadow: 0 0 0 0.15rem rgba(101, 148, 218, 0.5); }
  .btn-check:checked + .btn-blue,
  .btn-check:active + .btn-blue, .btn-blue:active, .btn-blue.active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #3b67aa;
    border-color: #38619f; }
    .btn-check:checked + .btn-blue:focus,
    .btn-check:active + .btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(101, 148, 218, 0.5); }
  .btn-blue:disabled, .btn-blue.disabled {
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4; }

.btn-outline-primary {
  color: #6658dd;
  border-color: #6658dd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #6658dd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #cedeef;
  border-color: #cedeef; }
  .btn-outline-secondary:hover {
    color: #36404a;
    background-color: #cedeef;
    border-color: #cedeef; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.15rem rgba(206, 222, 239, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #36404a;
    background-color: #cedeef;
    border-color: #cedeef; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(206, 222, 239, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #cedeef;
    background-color: transparent; }

.btn-outline-success {
  color: #1abc9c;
  border-color: #1abc9c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #1abc9c;
    background-color: transparent; }

.btn-outline-info {
  color: #4fc6e1;
  border-color: #4fc6e1; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.15rem rgba(79, 198, 225, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(79, 198, 225, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #4fc6e1;
    background-color: transparent; }

.btn-outline-warning {
  color: #f7b84b;
  border-color: #f7b84b; }
  .btn-outline-warning:hover {
    color: #36404a;
    background-color: #f7b84b;
    border-color: #f7b84b; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.15rem rgba(247, 184, 75, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #36404a;
    background-color: #f7b84b;
    border-color: #f7b84b; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(247, 184, 75, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #f7b84b;
    background-color: transparent; }

.btn-outline-danger {
  color: #f1556c;
  border-color: #f1556c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #f1556c;
    background-color: transparent; }

.btn-outline-light {
  color: #424e5a;
  border-color: #424e5a; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #424e5a;
    border-color: #424e5a; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #fff;
    background-color: #424e5a;
    border-color: #424e5a; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #424e5a;
    background-color: transparent; }

.btn-outline-dark {
  color: #f3f7f9;
  border-color: #f3f7f9; }
  .btn-outline-dark:hover {
    color: #36404a;
    background-color: #f3f7f9;
    border-color: #f3f7f9; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.15rem rgba(243, 247, 249, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #36404a;
    background-color: #f3f7f9;
    border-color: #f3f7f9; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(243, 247, 249, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #f3f7f9;
    background-color: transparent; }

.btn-outline-pink {
  color: #f672a7;
  border-color: #f672a7; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f672a7;
    border-color: #f672a7; }
  .btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
    box-shadow: 0 0 0 0.15rem rgba(246, 114, 167, 0.5); }
  .btn-check:checked + .btn-outline-pink,
  .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
    color: #fff;
    background-color: #f672a7;
    border-color: #f672a7; }
    .btn-check:checked + .btn-outline-pink:focus,
    .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(246, 114, 167, 0.5); }
  .btn-outline-pink:disabled, .btn-outline-pink.disabled {
    color: #f672a7;
    background-color: transparent; }

.btn-outline-blue {
  color: #4a81d4;
  border-color: #4a81d4; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4; }
  .btn-check:focus + .btn-outline-blue, .btn-outline-blue:focus {
    box-shadow: 0 0 0 0.15rem rgba(74, 129, 212, 0.5); }
  .btn-check:checked + .btn-outline-blue,
  .btn-check:active + .btn-outline-blue, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.dropdown-toggle.show {
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4; }
    .btn-check:checked + .btn-outline-blue:focus,
    .btn-check:active + .btn-outline-blue:focus, .btn-outline-blue:active:focus, .btn-outline-blue.active:focus, .btn-outline-blue.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(74, 129, 212, 0.5); }
  .btn-outline-blue:disabled, .btn-outline-blue.disabled {
    color: #4a81d4;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #6658dd;
  text-decoration: none; }
  .btn-link:hover {
    color: #fff;
    text-decoration: none; }
  .btn-link:focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #cedeef; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.05rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.756rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 0.84rem;
  color: #94a0ad;
  text-align: left;
  list-style: none;
  background-color: #3b4651;
  background-clip: padding-box;
  border: 1px solid #414d59;
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.45rem 0;
  overflow: hidden;
  border-top: 1px solid #475461; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #94a0ad;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #dbdee0;
    background-color: #424e5a; }
  .dropdown-item.active, .dropdown-item:active {
    color: #f3f7f9;
    text-decoration: none;
    background-color: #424e5a; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #5d7186;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.25rem 1.2rem;
  margin-bottom: 0;
  font-size: 0.756rem;
  color: inherit;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.2rem;
  color: #94a0ad; }

.dropdown-menu-dark {
  color: #424e5a;
  background-color: #f7f7f7;
  border-color: #414d59; }
  .dropdown-menu-dark .dropdown-item {
    color: #424e5a; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #f3f7f9;
      background-color: #424e5a; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #8c98a5; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: #475461; }
  .dropdown-menu-dark .dropdown-item-text {
    color: #424e5a; }
  .dropdown-menu-dark .dropdown-header {
    color: #8c98a5; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #6658dd;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #fff; }
  .nav-link.disabled {
    color: #cedeef;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #424e5a; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #36404a #36404a #424e5a;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #cedeef;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #dee2e6;
    background-color: #424e5a;
    border-color: #424e5a #424e5a #424e5a; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #6658dd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-right: 1.5rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3425rem;
  padding-bottom: 0.3425rem;
  margin-right: 1rem;
  font-size: 1.05rem;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.05rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.15rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.15rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(247, 247, 247, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(247, 247, 247, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(247, 247, 247, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(247, 247, 247, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(247, 247, 247, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(247, 247, 247, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(247, 247, 247, 0.5);
  border-color: rgba(247, 247, 247, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28247, 247, 247, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(247, 247, 247, 0.5); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(247, 247, 247, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #36404a;
  background-clip: border-box;
  border: 0 solid #36404a;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem; }

.card-title {
  margin-bottom: 1.005rem; }

.card-subtitle {
  margin-top: -0.5025rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 1.005rem 1.5rem;
  margin-bottom: 0;
  background-color: #424e5a;
  border-bottom: 0 solid #36404a; }
  .card-header:first-child {
    border-radius: 0.25rem 0.25rem 0 0; }

.card-footer {
  padding: 1.005rem 1.5rem;
  background-color: #424e5a;
  border-top: 0 solid #36404a; }
  .card-footer:last-child {
    border-radius: 0 0 0.25rem 0.25rem; }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.005rem;
  margin-left: -0.75rem;
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: #36404a;
    border-bottom-color: #36404a; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-group > .card {
  margin-bottom: 12px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.84rem;
  color: #94a0ad;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #5c4fc7;
    background-color: #f0eefc;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235c4fc7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2394a0ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #475461;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #5d7186;
    content: var(--bs-breadcrumb-divider, "󰅂") /* rtl: var(--bs-breadcrumb-divider, "󰅂") */; }

.breadcrumb-item.active {
  color: #8c98a5; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #f3f7f9;
  background-color: #3b4651;
  border: 1px solid #424e5a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #f3f7f9;
    background-color: #424e5a;
    border-color: #475461; }
  .page-link:focus {
    z-index: 3;
    color: #fff;
    background-color: #424e5a;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6658dd;
  border-color: #6658dd; }

.page-item.disabled .page-link {
  color: #cedeef;
  pointer-events: none;
  background-color: #424e5a;
  border-color: #424e5a; }

.page-link {
  padding: 0.5rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.05rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.756rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 3.75rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.9375rem 1.25rem; }

.alert-primary {
  color: #3d3585;
  background-color: #e0def8;
  border-color: #d1cdf5; }
  .alert-primary .alert-link {
    color: #312a6a; }

.alert-secondary {
  color: #7c858f;
  background-color: #f5f8fc;
  border-color: #f0f5fa; }
  .alert-secondary .alert-link {
    color: #636a72; }

.alert-success {
  color: #10715e;
  background-color: #d1f2eb;
  border-color: #baebe1; }
  .alert-success .alert-link {
    color: #0d5a4b; }

.alert-info {
  color: #2f7787;
  background-color: #dcf4f9;
  border-color: #caeef6; }
  .alert-info .alert-link {
    color: #265f6c; }

.alert-warning {
  color: #946e2d;
  background-color: #fdf1db;
  border-color: #fdeac9; }
  .alert-warning .alert-link {
    color: #765824; }

.alert-danger {
  color: #913341;
  background-color: #fcdde2;
  border-color: #fbccd3; }
  .alert-danger .alert-link {
    color: #742934; }

.alert-light {
  color: #282f36;
  background-color: #d9dcde;
  border-color: #c6cace; }
  .alert-light .alert-link {
    color: #20262b; }

.alert-dark {
  color: #929495;
  background-color: #fdfdfe;
  border-color: #fbfdfd; }
  .alert-dark .alert-link {
    color: #757677; }

.alert-pink {
  color: #944464;
  background-color: #fde3ed;
  border-color: #fcd5e5; }
  .alert-pink .alert-link {
    color: #763650; }

.alert-blue {
  color: #2c4d7f;
  background-color: #dbe6f6;
  border-color: #c9d9f2; }
  .alert-blue .alert-link {
    color: #233e66; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.75rem; } }

.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  font-size: 0.63rem;
  background-color: #424e5a;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #6658dd;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #8c98a5;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #8c98a5;
    text-decoration: none;
    background-color: #3b4651; }
  .list-group-item-action:active {
    color: #94a0ad;
    background-color: #36404a; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: inherit;
  background-color: #36404a;
  border: 1px solid #424e5a; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #cedeef;
    pointer-events: none;
    background-color: #36404a; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #3d3585;
  background-color: #e0def8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3d3585;
    background-color: #cac8df; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3d3585;
    border-color: #3d3585; }

.list-group-item-secondary {
  color: #7c858f;
  background-color: #f5f8fc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7c858f;
    background-color: #dddfe3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7c858f;
    border-color: #7c858f; }

.list-group-item-success {
  color: #10715e;
  background-color: #d1f2eb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #10715e;
    background-color: #bcdad4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #10715e;
    border-color: #10715e; }

.list-group-item-info {
  color: #2f7787;
  background-color: #dcf4f9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2f7787;
    background-color: #c6dce0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #2f7787;
    border-color: #2f7787; }

.list-group-item-warning {
  color: #946e2d;
  background-color: #fdf1db; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #946e2d;
    background-color: #e4d9c5; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #946e2d;
    border-color: #946e2d; }

.list-group-item-danger {
  color: #913341;
  background-color: #fcdde2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #913341;
    background-color: #e3c7cb; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #913341;
    border-color: #913341; }

.list-group-item-light {
  color: #282f36;
  background-color: #d9dcde; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #282f36;
    background-color: #c3c6c8; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #282f36;
    border-color: #282f36; }

.list-group-item-dark {
  color: #929495;
  background-color: #fdfdfe; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #929495;
    background-color: #e4e4e5; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #929495;
    border-color: #929495; }

.list-group-item-pink {
  color: #944464;
  background-color: #fde3ed; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #944464;
    background-color: #e4ccd5; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #944464;
    border-color: #944464; }

.list-group-item-blue {
  color: #2c4d7f;
  background-color: #dbe6f6; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #2c4d7f;
    background-color: #c5cfdd; }
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #2c4d7f;
    border-color: #2c4d7f; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #f7f7f7;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f7f7f7'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #f7f7f7;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(54, 64, 74, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 12px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #cedeef;
  background-color: rgba(50, 58, 70, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #36404a;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #f3f7f9; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #414d59;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem; }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #414d59;
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.756rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.375rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.756rem;
  word-wrap: break-word;
  background-color: #5d7186;
  background-clip: padding-box;
  border: 1px solid #424e5a;
  border-radius: 0.25rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #424e5a; }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #5d7186; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #424e5a; }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #5d7186; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #424e5a; }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #5d7186; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #323a46; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #424e5a; }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #5d7186; }

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.84rem;
  background-color: #323a46;
  border-bottom: 1px solid #424e5a;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.7rem 0.8rem;
  color: #94a0ad; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #36404a;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #f3f7f9; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0 solid transparent;
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0 solid transparent;
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid transparent;
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid transparent;
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #6658dd; }
  .link-primary:hover, .link-primary:focus {
    color: #473e9b; }

.link-secondary {
  color: #cedeef; }
  .link-secondary:hover, .link-secondary:focus {
    color: #dde8f4; }

.link-success {
  color: #1abc9c; }
  .link-success:hover, .link-success:focus {
    color: #12846d; }

.link-info {
  color: #4fc6e1; }
  .link-info:hover, .link-info:focus {
    color: #378b9e; }

.link-warning {
  color: #f7b84b; }
  .link-warning:hover, .link-warning:focus {
    color: #f9cd81; }

.link-danger {
  color: #f1556c; }
  .link-danger:hover, .link-danger:focus {
    color: #a93c4c; }

.link-light {
  color: #424e5a; }
  .link-light:hover, .link-light:focus {
    color: #2e373f; }

.link-dark {
  color: #f3f7f9; }
  .link-dark:hover, .link-dark:focus {
    color: #f7f9fb; }

.link-pink {
  color: #f672a7; }
  .link-pink:hover, .link-pink:focus {
    color: #ac5075; }

.link-blue {
  color: #4a81d4; }
  .link-blue:hover, .link-blue:focus {
    color: #345a94; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03) !important; }

.shadow-lg {
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #424e5a !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #424e5a !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #424e5a !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #424e5a !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #424e5a !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #6658dd !important; }

.border-secondary {
  border-color: #cedeef !important; }

.border-success {
  border-color: #1abc9c !important; }

.border-info {
  border-color: #4fc6e1 !important; }

.border-warning {
  border-color: #f7b84b !important; }

.border-danger {
  border-color: #f1556c !important; }

.border-light {
  border-color: #424e5a !important; }

.border-dark {
  border-color: #f3f7f9 !important; }

.border-pink {
  border-color: #f672a7 !important; }

.border-blue {
  border-color: #4a81d4 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.375rem !important; }

.gap-2 {
  gap: 0.75rem !important; }

.gap-3 {
  gap: 1.5rem !important; }

.gap-4 {
  gap: 2.25rem !important; }

.gap-5 {
  gap: 4.5rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.375rem !important; }

.m-2 {
  margin: 0.75rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important; }

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important; }

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important; }

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important; }

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important; }

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.375rem !important; }

.mt-2 {
  margin-top: 0.75rem !important; }

.mt-3 {
  margin-top: 1.5rem !important; }

.mt-4 {
  margin-top: 2.25rem !important; }

.mt-5 {
  margin-top: 4.5rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.375rem !important; }

.me-2 {
  margin-right: 0.75rem !important; }

.me-3 {
  margin-right: 1.5rem !important; }

.me-4 {
  margin-right: 2.25rem !important; }

.me-5 {
  margin-right: 4.5rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.375rem !important; }

.mb-2 {
  margin-bottom: 0.75rem !important; }

.mb-3 {
  margin-bottom: 1.5rem !important; }

.mb-4 {
  margin-bottom: 2.25rem !important; }

.mb-5 {
  margin-bottom: 4.5rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.375rem !important; }

.ms-2 {
  margin-left: 0.75rem !important; }

.ms-3 {
  margin-left: 1.5rem !important; }

.ms-4 {
  margin-left: 2.25rem !important; }

.ms-5 {
  margin-left: 4.5rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.375rem !important; }

.m-n2 {
  margin: -0.75rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important; }

.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important; }

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important; }

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important; }

.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important; }

.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important; }

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important; }

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important; }

.mt-n1 {
  margin-top: -0.375rem !important; }

.mt-n2 {
  margin-top: -0.75rem !important; }

.mt-n3 {
  margin-top: -1.5rem !important; }

.mt-n4 {
  margin-top: -2.25rem !important; }

.mt-n5 {
  margin-top: -4.5rem !important; }

.me-n1 {
  margin-right: -0.375rem !important; }

.me-n2 {
  margin-right: -0.75rem !important; }

.me-n3 {
  margin-right: -1.5rem !important; }

.me-n4 {
  margin-right: -2.25rem !important; }

.me-n5 {
  margin-right: -4.5rem !important; }

.mb-n1 {
  margin-bottom: -0.375rem !important; }

.mb-n2 {
  margin-bottom: -0.75rem !important; }

.mb-n3 {
  margin-bottom: -1.5rem !important; }

.mb-n4 {
  margin-bottom: -2.25rem !important; }

.mb-n5 {
  margin-bottom: -4.5rem !important; }

.ms-n1 {
  margin-left: -0.375rem !important; }

.ms-n2 {
  margin-left: -0.75rem !important; }

.ms-n3 {
  margin-left: -1.5rem !important; }

.ms-n4 {
  margin-left: -2.25rem !important; }

.ms-n5 {
  margin-left: -4.5rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.375rem !important; }

.p-2 {
  padding: 0.75rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important; }

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important; }

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important; }

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important; }

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important; }

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.375rem !important; }

.pt-2 {
  padding-top: 0.75rem !important; }

.pt-3 {
  padding-top: 1.5rem !important; }

.pt-4 {
  padding-top: 2.25rem !important; }

.pt-5 {
  padding-top: 4.5rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.375rem !important; }

.pe-2 {
  padding-right: 0.75rem !important; }

.pe-3 {
  padding-right: 1.5rem !important; }

.pe-4 {
  padding-right: 2.25rem !important; }

.pe-5 {
  padding-right: 4.5rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.375rem !important; }

.pb-2 {
  padding-bottom: 0.75rem !important; }

.pb-3 {
  padding-bottom: 1.5rem !important; }

.pb-4 {
  padding-bottom: 2.25rem !important; }

.pb-5 {
  padding-bottom: 4.5rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.375rem !important; }

.ps-2 {
  padding-left: 0.75rem !important; }

.ps-3 {
  padding-left: 1.5rem !important; }

.ps-4 {
  padding-left: 2.25rem !important; }

.ps-5 {
  padding-left: 4.5rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 0.9375rem !important; }

.fs-6 {
  font-size: 0.75rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 500 !important; }

.fw-bolder {
  font-weight: 500 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important; }

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #8c98a5 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important; }

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.375rem !important; }
  .gap-sm-2 {
    gap: 0.75rem !important; }
  .gap-sm-3 {
    gap: 1.5rem !important; }
  .gap-sm-4 {
    gap: 2.25rem !important; }
  .gap-sm-5 {
    gap: 4.5rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.375rem !important; }
  .m-sm-2 {
    margin: 0.75rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.375rem !important; }
  .mt-sm-2 {
    margin-top: 0.75rem !important; }
  .mt-sm-3 {
    margin-top: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 2.25rem !important; }
  .mt-sm-5 {
    margin-top: 4.5rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.375rem !important; }
  .me-sm-2 {
    margin-right: 0.75rem !important; }
  .me-sm-3 {
    margin-right: 1.5rem !important; }
  .me-sm-4 {
    margin-right: 2.25rem !important; }
  .me-sm-5 {
    margin-right: 4.5rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important; }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 2.25rem !important; }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.375rem !important; }
  .ms-sm-2 {
    margin-left: 0.75rem !important; }
  .ms-sm-3 {
    margin-left: 1.5rem !important; }
  .ms-sm-4 {
    margin-left: 2.25rem !important; }
  .ms-sm-5 {
    margin-left: 4.5rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.375rem !important; }
  .m-sm-n2 {
    margin: -0.75rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-sm-n1 {
    margin-top: -0.375rem !important; }
  .mt-sm-n2 {
    margin-top: -0.75rem !important; }
  .mt-sm-n3 {
    margin-top: -1.5rem !important; }
  .mt-sm-n4 {
    margin-top: -2.25rem !important; }
  .mt-sm-n5 {
    margin-top: -4.5rem !important; }
  .me-sm-n1 {
    margin-right: -0.375rem !important; }
  .me-sm-n2 {
    margin-right: -0.75rem !important; }
  .me-sm-n3 {
    margin-right: -1.5rem !important; }
  .me-sm-n4 {
    margin-right: -2.25rem !important; }
  .me-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-sm-n1 {
    margin-left: -0.375rem !important; }
  .ms-sm-n2 {
    margin-left: -0.75rem !important; }
  .ms-sm-n3 {
    margin-left: -1.5rem !important; }
  .ms-sm-n4 {
    margin-left: -2.25rem !important; }
  .ms-sm-n5 {
    margin-left: -4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.375rem !important; }
  .p-sm-2 {
    padding: 0.75rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.375rem !important; }
  .pt-sm-2 {
    padding-top: 0.75rem !important; }
  .pt-sm-3 {
    padding-top: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 2.25rem !important; }
  .pt-sm-5 {
    padding-top: 4.5rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.375rem !important; }
  .pe-sm-2 {
    padding-right: 0.75rem !important; }
  .pe-sm-3 {
    padding-right: 1.5rem !important; }
  .pe-sm-4 {
    padding-right: 2.25rem !important; }
  .pe-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important; }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.375rem !important; }
  .ps-sm-2 {
    padding-left: 0.75rem !important; }
  .ps-sm-3 {
    padding-left: 1.5rem !important; }
  .ps-sm-4 {
    padding-left: 2.25rem !important; }
  .ps-sm-5 {
    padding-left: 4.5rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.375rem !important; }
  .gap-md-2 {
    gap: 0.75rem !important; }
  .gap-md-3 {
    gap: 1.5rem !important; }
  .gap-md-4 {
    gap: 2.25rem !important; }
  .gap-md-5 {
    gap: 4.5rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.375rem !important; }
  .m-md-2 {
    margin: 0.75rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.375rem !important; }
  .mt-md-2 {
    margin-top: 0.75rem !important; }
  .mt-md-3 {
    margin-top: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 2.25rem !important; }
  .mt-md-5 {
    margin-top: 4.5rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.375rem !important; }
  .me-md-2 {
    margin-right: 0.75rem !important; }
  .me-md-3 {
    margin-right: 1.5rem !important; }
  .me-md-4 {
    margin-right: 2.25rem !important; }
  .me-md-5 {
    margin-right: 4.5rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.375rem !important; }
  .mb-md-2 {
    margin-bottom: 0.75rem !important; }
  .mb-md-3 {
    margin-bottom: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 2.25rem !important; }
  .mb-md-5 {
    margin-bottom: 4.5rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.375rem !important; }
  .ms-md-2 {
    margin-left: 0.75rem !important; }
  .ms-md-3 {
    margin-left: 1.5rem !important; }
  .ms-md-4 {
    margin-left: 2.25rem !important; }
  .ms-md-5 {
    margin-left: 4.5rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.375rem !important; }
  .m-md-n2 {
    margin: -0.75rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-md-n1 {
    margin-top: -0.375rem !important; }
  .mt-md-n2 {
    margin-top: -0.75rem !important; }
  .mt-md-n3 {
    margin-top: -1.5rem !important; }
  .mt-md-n4 {
    margin-top: -2.25rem !important; }
  .mt-md-n5 {
    margin-top: -4.5rem !important; }
  .me-md-n1 {
    margin-right: -0.375rem !important; }
  .me-md-n2 {
    margin-right: -0.75rem !important; }
  .me-md-n3 {
    margin-right: -1.5rem !important; }
  .me-md-n4 {
    margin-right: -2.25rem !important; }
  .me-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-md-n1 {
    margin-left: -0.375rem !important; }
  .ms-md-n2 {
    margin-left: -0.75rem !important; }
  .ms-md-n3 {
    margin-left: -1.5rem !important; }
  .ms-md-n4 {
    margin-left: -2.25rem !important; }
  .ms-md-n5 {
    margin-left: -4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.375rem !important; }
  .p-md-2 {
    padding: 0.75rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.375rem !important; }
  .pt-md-2 {
    padding-top: 0.75rem !important; }
  .pt-md-3 {
    padding-top: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 2.25rem !important; }
  .pt-md-5 {
    padding-top: 4.5rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.375rem !important; }
  .pe-md-2 {
    padding-right: 0.75rem !important; }
  .pe-md-3 {
    padding-right: 1.5rem !important; }
  .pe-md-4 {
    padding-right: 2.25rem !important; }
  .pe-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.375rem !important; }
  .pb-md-2 {
    padding-bottom: 0.75rem !important; }
  .pb-md-3 {
    padding-bottom: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 2.25rem !important; }
  .pb-md-5 {
    padding-bottom: 4.5rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.375rem !important; }
  .ps-md-2 {
    padding-left: 0.75rem !important; }
  .ps-md-3 {
    padding-left: 1.5rem !important; }
  .ps-md-4 {
    padding-left: 2.25rem !important; }
  .ps-md-5 {
    padding-left: 4.5rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.375rem !important; }
  .gap-lg-2 {
    gap: 0.75rem !important; }
  .gap-lg-3 {
    gap: 1.5rem !important; }
  .gap-lg-4 {
    gap: 2.25rem !important; }
  .gap-lg-5 {
    gap: 4.5rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.375rem !important; }
  .m-lg-2 {
    margin: 0.75rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.375rem !important; }
  .mt-lg-2 {
    margin-top: 0.75rem !important; }
  .mt-lg-3 {
    margin-top: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 2.25rem !important; }
  .mt-lg-5 {
    margin-top: 4.5rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.375rem !important; }
  .me-lg-2 {
    margin-right: 0.75rem !important; }
  .me-lg-3 {
    margin-right: 1.5rem !important; }
  .me-lg-4 {
    margin-right: 2.25rem !important; }
  .me-lg-5 {
    margin-right: 4.5rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important; }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 2.25rem !important; }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.375rem !important; }
  .ms-lg-2 {
    margin-left: 0.75rem !important; }
  .ms-lg-3 {
    margin-left: 1.5rem !important; }
  .ms-lg-4 {
    margin-left: 2.25rem !important; }
  .ms-lg-5 {
    margin-left: 4.5rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.375rem !important; }
  .m-lg-n2 {
    margin: -0.75rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-lg-n1 {
    margin-top: -0.375rem !important; }
  .mt-lg-n2 {
    margin-top: -0.75rem !important; }
  .mt-lg-n3 {
    margin-top: -1.5rem !important; }
  .mt-lg-n4 {
    margin-top: -2.25rem !important; }
  .mt-lg-n5 {
    margin-top: -4.5rem !important; }
  .me-lg-n1 {
    margin-right: -0.375rem !important; }
  .me-lg-n2 {
    margin-right: -0.75rem !important; }
  .me-lg-n3 {
    margin-right: -1.5rem !important; }
  .me-lg-n4 {
    margin-right: -2.25rem !important; }
  .me-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-lg-n1 {
    margin-left: -0.375rem !important; }
  .ms-lg-n2 {
    margin-left: -0.75rem !important; }
  .ms-lg-n3 {
    margin-left: -1.5rem !important; }
  .ms-lg-n4 {
    margin-left: -2.25rem !important; }
  .ms-lg-n5 {
    margin-left: -4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.375rem !important; }
  .p-lg-2 {
    padding: 0.75rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.375rem !important; }
  .pt-lg-2 {
    padding-top: 0.75rem !important; }
  .pt-lg-3 {
    padding-top: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 2.25rem !important; }
  .pt-lg-5 {
    padding-top: 4.5rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.375rem !important; }
  .pe-lg-2 {
    padding-right: 0.75rem !important; }
  .pe-lg-3 {
    padding-right: 1.5rem !important; }
  .pe-lg-4 {
    padding-right: 2.25rem !important; }
  .pe-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important; }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.375rem !important; }
  .ps-lg-2 {
    padding-left: 0.75rem !important; }
  .ps-lg-3 {
    padding-left: 1.5rem !important; }
  .ps-lg-4 {
    padding-left: 2.25rem !important; }
  .ps-lg-5 {
    padding-left: 4.5rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.375rem !important; }
  .gap-xl-2 {
    gap: 0.75rem !important; }
  .gap-xl-3 {
    gap: 1.5rem !important; }
  .gap-xl-4 {
    gap: 2.25rem !important; }
  .gap-xl-5 {
    gap: 4.5rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.375rem !important; }
  .m-xl-2 {
    margin: 0.75rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.375rem !important; }
  .mt-xl-2 {
    margin-top: 0.75rem !important; }
  .mt-xl-3 {
    margin-top: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 2.25rem !important; }
  .mt-xl-5 {
    margin-top: 4.5rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.375rem !important; }
  .me-xl-2 {
    margin-right: 0.75rem !important; }
  .me-xl-3 {
    margin-right: 1.5rem !important; }
  .me-xl-4 {
    margin-right: 2.25rem !important; }
  .me-xl-5 {
    margin-right: 4.5rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important; }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 2.25rem !important; }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.375rem !important; }
  .ms-xl-2 {
    margin-left: 0.75rem !important; }
  .ms-xl-3 {
    margin-left: 1.5rem !important; }
  .ms-xl-4 {
    margin-left: 2.25rem !important; }
  .ms-xl-5 {
    margin-left: 4.5rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.375rem !important; }
  .m-xl-n2 {
    margin: -0.75rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-xl-n1 {
    margin-top: -0.375rem !important; }
  .mt-xl-n2 {
    margin-top: -0.75rem !important; }
  .mt-xl-n3 {
    margin-top: -1.5rem !important; }
  .mt-xl-n4 {
    margin-top: -2.25rem !important; }
  .mt-xl-n5 {
    margin-top: -4.5rem !important; }
  .me-xl-n1 {
    margin-right: -0.375rem !important; }
  .me-xl-n2 {
    margin-right: -0.75rem !important; }
  .me-xl-n3 {
    margin-right: -1.5rem !important; }
  .me-xl-n4 {
    margin-right: -2.25rem !important; }
  .me-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-xl-n1 {
    margin-left: -0.375rem !important; }
  .ms-xl-n2 {
    margin-left: -0.75rem !important; }
  .ms-xl-n3 {
    margin-left: -1.5rem !important; }
  .ms-xl-n4 {
    margin-left: -2.25rem !important; }
  .ms-xl-n5 {
    margin-left: -4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.375rem !important; }
  .p-xl-2 {
    padding: 0.75rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.375rem !important; }
  .pt-xl-2 {
    padding-top: 0.75rem !important; }
  .pt-xl-3 {
    padding-top: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 2.25rem !important; }
  .pt-xl-5 {
    padding-top: 4.5rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.375rem !important; }
  .pe-xl-2 {
    padding-right: 0.75rem !important; }
  .pe-xl-3 {
    padding-right: 1.5rem !important; }
  .pe-xl-4 {
    padding-right: 2.25rem !important; }
  .pe-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important; }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.375rem !important; }
  .ps-xl-2 {
    padding-left: 0.75rem !important; }
  .ps-xl-3 {
    padding-left: 1.5rem !important; }
  .ps-xl-4 {
    padding-left: 2.25rem !important; }
  .ps-xl-5 {
    padding-left: 4.5rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.375rem !important; }
  .gap-xxl-2 {
    gap: 0.75rem !important; }
  .gap-xxl-3 {
    gap: 1.5rem !important; }
  .gap-xxl-4 {
    gap: 2.25rem !important; }
  .gap-xxl-5 {
    gap: 4.5rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.375rem !important; }
  .m-xxl-2 {
    margin: 0.75rem !important; }
  .m-xxl-3 {
    margin: 1.5rem !important; }
  .m-xxl-4 {
    margin: 2.25rem !important; }
  .m-xxl-5 {
    margin: 4.5rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.375rem !important; }
  .mt-xxl-2 {
    margin-top: 0.75rem !important; }
  .mt-xxl-3 {
    margin-top: 1.5rem !important; }
  .mt-xxl-4 {
    margin-top: 2.25rem !important; }
  .mt-xxl-5 {
    margin-top: 4.5rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.375rem !important; }
  .me-xxl-2 {
    margin-right: 0.75rem !important; }
  .me-xxl-3 {
    margin-right: 1.5rem !important; }
  .me-xxl-4 {
    margin-right: 2.25rem !important; }
  .me-xxl-5 {
    margin-right: 4.5rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-4 {
    margin-bottom: 2.25rem !important; }
  .mb-xxl-5 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.375rem !important; }
  .ms-xxl-2 {
    margin-left: 0.75rem !important; }
  .ms-xxl-3 {
    margin-left: 1.5rem !important; }
  .ms-xxl-4 {
    margin-left: 2.25rem !important; }
  .ms-xxl-5 {
    margin-left: 4.5rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.375rem !important; }
  .m-xxl-n2 {
    margin: -0.75rem !important; }
  .m-xxl-n3 {
    margin: -1.5rem !important; }
  .m-xxl-n4 {
    margin: -2.25rem !important; }
  .m-xxl-n5 {
    margin: -4.5rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-xxl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important; }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n4 {
    margin-top: -2.25rem !important; }
  .mt-xxl-n5 {
    margin-top: -4.5rem !important; }
  .me-xxl-n1 {
    margin-right: -0.375rem !important; }
  .me-xxl-n2 {
    margin-right: -0.75rem !important; }
  .me-xxl-n3 {
    margin-right: -1.5rem !important; }
  .me-xxl-n4 {
    margin-right: -2.25rem !important; }
  .me-xxl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important; }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n4 {
    margin-left: -2.25rem !important; }
  .ms-xxl-n5 {
    margin-left: -4.5rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.375rem !important; }
  .p-xxl-2 {
    padding: 0.75rem !important; }
  .p-xxl-3 {
    padding: 1.5rem !important; }
  .p-xxl-4 {
    padding: 2.25rem !important; }
  .p-xxl-5 {
    padding: 4.5rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.375rem !important; }
  .pt-xxl-2 {
    padding-top: 0.75rem !important; }
  .pt-xxl-3 {
    padding-top: 1.5rem !important; }
  .pt-xxl-4 {
    padding-top: 2.25rem !important; }
  .pt-xxl-5 {
    padding-top: 4.5rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.375rem !important; }
  .pe-xxl-2 {
    padding-right: 0.75rem !important; }
  .pe-xxl-3 {
    padding-right: 1.5rem !important; }
  .pe-xxl-4 {
    padding-right: 2.25rem !important; }
  .pe-xxl-5 {
    padding-right: 4.5rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-4 {
    padding-bottom: 2.25rem !important; }
  .pb-xxl-5 {
    padding-bottom: 4.5rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.375rem !important; }
  .ps-xxl-2 {
    padding-left: 0.75rem !important; }
  .ps-xxl-3 {
    padding-left: 1.5rem !important; }
  .ps-xxl-4 {
    padding-left: 2.25rem !important; }
  .ps-xxl-5 {
    padding-left: 4.5rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.875rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0; }

.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\F0142"; }

.badge {
  box-shadow: none; }

.badge-soft-primary {
  color: #6658dd;
  background-color: rgba(102, 88, 221, 0.18);
  box-shadow: none; }

.badge-soft-secondary {
  color: #cedeef;
  background-color: rgba(206, 222, 239, 0.18);
  box-shadow: none; }

.badge-soft-success {
  color: #1abc9c;
  background-color: rgba(26, 188, 156, 0.18);
  box-shadow: none; }

.badge-soft-info {
  color: #4fc6e1;
  background-color: rgba(79, 198, 225, 0.18);
  box-shadow: none; }

.badge-soft-warning {
  color: #f7b84b;
  background-color: rgba(247, 184, 75, 0.18);
  box-shadow: none; }

.badge-soft-danger {
  color: #f1556c;
  background-color: rgba(241, 85, 108, 0.18);
  box-shadow: none; }

.badge-soft-light {
  color: #424e5a;
  background-color: rgba(66, 78, 90, 0.18);
  box-shadow: none; }

.badge-soft-dark {
  color: #f3f7f9;
  background-color: rgba(243, 247, 249, 0.18);
  box-shadow: none; }

.badge-soft-pink {
  color: #f672a7;
  background-color: rgba(246, 114, 167, 0.18);
  box-shadow: none; }

.badge-soft-blue {
  color: #4a81d4;
  background-color: rgba(74, 129, 212, 0.18);
  box-shadow: none; }

.badge-outline-primary {
  color: #6658dd;
  border: 1px solid #6658dd;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-secondary {
  color: #cedeef;
  border: 1px solid #cedeef;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-success {
  color: #1abc9c;
  border: 1px solid #1abc9c;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-info {
  color: #4fc6e1;
  border: 1px solid #4fc6e1;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-warning {
  color: #f7b84b;
  border: 1px solid #f7b84b;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-danger {
  color: #f1556c;
  border: 1px solid #f1556c;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-light {
  color: #424e5a;
  border: 1px solid #424e5a;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-dark {
  color: #f3f7f9;
  border: 1px solid #f3f7f9;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-pink {
  color: #f672a7;
  border: 1px solid #f672a7;
  background-color: transparent;
  box-shadow: none; }

.badge-outline-blue {
  color: #4a81d4;
  border: 1px solid #4a81d4;
  background-color: transparent;
  box-shadow: none; }

.bg-soft-primary {
  background-color: rgba(102, 88, 221, 0.25) !important; }

.bg-soft-secondary {
  background-color: rgba(206, 222, 239, 0.25) !important; }

.bg-soft-success {
  background-color: rgba(26, 188, 156, 0.25) !important; }

.bg-soft-info {
  background-color: rgba(79, 198, 225, 0.25) !important; }

.bg-soft-warning {
  background-color: rgba(247, 184, 75, 0.25) !important; }

.bg-soft-danger {
  background-color: rgba(241, 85, 108, 0.25) !important; }

.bg-soft-light {
  background-color: rgba(66, 78, 90, 0.25) !important; }

.bg-soft-dark {
  background-color: rgba(243, 247, 249, 0.25) !important; }

.bg-soft-pink {
  background-color: rgba(246, 114, 167, 0.25) !important; }

.bg-soft-blue {
  background-color: rgba(74, 129, 212, 0.25) !important; }

.bg-ghost {
  opacity: 0.4; }

.breadcrumb-item > a {
  color: #dee2e6; }

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons"; }

.btn-primary {
  box-shadow: 0 0 0 rgba(102, 88, 221, 0.5); }

.btn-secondary {
  box-shadow: 0 0 0 rgba(206, 222, 239, 0.5); }

.btn-success {
  box-shadow: 0 0 0 rgba(26, 188, 156, 0.5); }

.btn-info {
  box-shadow: 0 0 0 rgba(79, 198, 225, 0.5); }

.btn-warning {
  box-shadow: 0 0 0 rgba(247, 184, 75, 0.5); }

.btn-danger {
  box-shadow: 0 0 0 rgba(241, 85, 108, 0.5); }

.btn-light {
  box-shadow: 0 0 0 rgba(66, 78, 90, 0.5); }

.btn-dark {
  box-shadow: 0 0 0 rgba(243, 247, 249, 0.5); }

.btn-pink {
  box-shadow: 0 0 0 rgba(246, 114, 167, 0.5); }

.btn-blue {
  box-shadow: 0 0 0 rgba(74, 129, 212, 0.5); }

.btn .mdi:before {
  margin-top: -1px; }

.btn i {
  display: inline-block; }

.btn-light,
.btn-white {
  color: #f3f7f9; }

.btn-white {
  border-color: #424e5a; }
  .btn-white:hover, .btn-white:focus {
    background-color: #323a46;
    border-color: #323a46; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.3); }

.btn-link {
  font-weight: 400;
  color: #6658dd;
  background-color: transparent; }
  .btn-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #cedeef;
    pointer-events: none; }

.btn-outline-primary {
  color: #6658dd;
  border-color: #6658dd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #6658dd;
    border-color: #6658dd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #6658dd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #cedeef;
  border-color: #cedeef; }
  .btn-outline-secondary:hover {
    color: #36404a;
    background-color: #cedeef;
    border-color: #cedeef; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.15rem rgba(206, 222, 239, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #36404a;
    background-color: #cedeef;
    border-color: #cedeef; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(206, 222, 239, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #cedeef;
    background-color: transparent; }

.btn-outline-success {
  color: #1abc9c;
  border-color: #1abc9c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #1abc9c;
    background-color: transparent; }

.btn-outline-info {
  color: #4fc6e1;
  border-color: #4fc6e1; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.15rem rgba(79, 198, 225, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #4fc6e1;
    border-color: #4fc6e1; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(79, 198, 225, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #4fc6e1;
    background-color: transparent; }

.btn-outline-warning {
  color: #f7b84b;
  border-color: #f7b84b; }
  .btn-outline-warning:hover {
    color: #36404a;
    background-color: #f7b84b;
    border-color: #f7b84b; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.15rem rgba(247, 184, 75, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #36404a;
    background-color: #f7b84b;
    border-color: #f7b84b; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(247, 184, 75, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #f7b84b;
    background-color: transparent; }

.btn-outline-danger {
  color: #f1556c;
  border-color: #f1556c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #f1556c;
    background-color: transparent; }

.btn-outline-light {
  color: #424e5a;
  border-color: #424e5a; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #424e5a;
    border-color: #424e5a; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #fff;
    background-color: #424e5a;
    border-color: #424e5a; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #424e5a;
    background-color: transparent; }

.btn-outline-dark {
  color: #f3f7f9;
  border-color: #f3f7f9; }
  .btn-outline-dark:hover {
    color: #36404a;
    background-color: #f3f7f9;
    border-color: #f3f7f9; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.15rem rgba(243, 247, 249, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #36404a;
    background-color: #f3f7f9;
    border-color: #f3f7f9; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(243, 247, 249, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #f3f7f9;
    background-color: transparent; }

.btn-outline-pink {
  color: #f672a7;
  border-color: #f672a7; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f672a7;
    border-color: #f672a7; }
  .btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
    box-shadow: 0 0 0 0.15rem rgba(246, 114, 167, 0.5); }
  .btn-check:checked + .btn-outline-pink,
  .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
    color: #fff;
    background-color: #f672a7;
    border-color: #f672a7; }
    .btn-check:checked + .btn-outline-pink:focus,
    .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(246, 114, 167, 0.5); }
  .btn-outline-pink:disabled, .btn-outline-pink.disabled {
    color: #f672a7;
    background-color: transparent; }

.btn-outline-blue {
  color: #4a81d4;
  border-color: #4a81d4; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4; }
  .btn-check:focus + .btn-outline-blue, .btn-outline-blue:focus {
    box-shadow: 0 0 0 0.15rem rgba(74, 129, 212, 0.5); }
  .btn-check:checked + .btn-outline-blue,
  .btn-check:active + .btn-outline-blue, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.dropdown-toggle.show {
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4; }
    .btn-check:checked + .btn-outline-blue:focus,
    .btn-check:active + .btn-outline-blue:focus, .btn-outline-blue:active:focus, .btn-outline-blue.active:focus, .btn-outline-blue.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(74, 129, 212, 0.5); }
  .btn-outline-blue:disabled, .btn-outline-blue.disabled {
    color: #4a81d4;
    background-color: transparent; }

.btn-label {
  margin: -.55rem .9rem -.55rem -.9rem;
  padding: .6rem .9rem;
  background-color: rgba(243, 247, 249, 0.1); }

.btn-label-right {
  margin: -0.45rem -0.9rem -0.45rem 0.9rem;
  padding: 0.45rem 0.9rem;
  background-color: rgba(243, 247, 249, 0.1);
  display: inline-block; }

.btn-xs {
  padding: .2rem .6rem;
  font-size: .75rem;
  border-radius: .15rem; }

.btn-soft-primary {
  color: #6658dd;
  background-color: rgba(102, 88, 221, 0.18);
  border-color: rgba(102, 88, 221, 0.12); }
  .btn-soft-primary:hover {
    color: #fff;
    background-color: #6658dd; }
  .btn-soft-primary:focus, .btn-soft-primary.focus {
    box-shadow: 0 0 0 0.15rem rgba(102, 88, 221, 0.3); }

.btn-soft-secondary {
  color: #cedeef;
  background-color: rgba(206, 222, 239, 0.18);
  border-color: rgba(206, 222, 239, 0.12); }
  .btn-soft-secondary:hover {
    color: #fff;
    background-color: #cedeef; }
  .btn-soft-secondary:focus, .btn-soft-secondary.focus {
    box-shadow: 0 0 0 0.15rem rgba(206, 222, 239, 0.3); }

.btn-soft-success {
  color: #1abc9c;
  background-color: rgba(26, 188, 156, 0.18);
  border-color: rgba(26, 188, 156, 0.12); }
  .btn-soft-success:hover {
    color: #fff;
    background-color: #1abc9c; }
  .btn-soft-success:focus, .btn-soft-success.focus {
    box-shadow: 0 0 0 0.15rem rgba(26, 188, 156, 0.3); }

.btn-soft-info {
  color: #4fc6e1;
  background-color: rgba(79, 198, 225, 0.18);
  border-color: rgba(79, 198, 225, 0.12); }
  .btn-soft-info:hover {
    color: #fff;
    background-color: #4fc6e1; }
  .btn-soft-info:focus, .btn-soft-info.focus {
    box-shadow: 0 0 0 0.15rem rgba(79, 198, 225, 0.3); }

.btn-soft-warning {
  color: #f7b84b;
  background-color: rgba(247, 184, 75, 0.18);
  border-color: rgba(247, 184, 75, 0.12); }
  .btn-soft-warning:hover {
    color: #fff;
    background-color: #f7b84b; }
  .btn-soft-warning:focus, .btn-soft-warning.focus {
    box-shadow: 0 0 0 0.15rem rgba(247, 184, 75, 0.3); }

.btn-soft-danger {
  color: #f1556c;
  background-color: rgba(241, 85, 108, 0.18);
  border-color: rgba(241, 85, 108, 0.12); }
  .btn-soft-danger:hover {
    color: #fff;
    background-color: #f1556c; }
  .btn-soft-danger:focus, .btn-soft-danger.focus {
    box-shadow: 0 0 0 0.15rem rgba(241, 85, 108, 0.3); }

.btn-soft-light {
  color: #424e5a;
  background-color: rgba(66, 78, 90, 0.18);
  border-color: rgba(66, 78, 90, 0.12); }
  .btn-soft-light:hover {
    color: #fff;
    background-color: #424e5a; }
  .btn-soft-light:focus, .btn-soft-light.focus {
    box-shadow: 0 0 0 0.15rem rgba(66, 78, 90, 0.3); }

.btn-soft-dark {
  color: #f3f7f9;
  background-color: rgba(243, 247, 249, 0.18);
  border-color: rgba(243, 247, 249, 0.12); }
  .btn-soft-dark:hover {
    color: #fff;
    background-color: #f3f7f9; }
  .btn-soft-dark:focus, .btn-soft-dark.focus {
    box-shadow: 0 0 0 0.15rem rgba(243, 247, 249, 0.3); }

.btn-soft-pink {
  color: #f672a7;
  background-color: rgba(246, 114, 167, 0.18);
  border-color: rgba(246, 114, 167, 0.12); }
  .btn-soft-pink:hover {
    color: #fff;
    background-color: #f672a7; }
  .btn-soft-pink:focus, .btn-soft-pink.focus {
    box-shadow: 0 0 0 0.15rem rgba(246, 114, 167, 0.3); }

.btn-soft-blue {
  color: #4a81d4;
  background-color: rgba(74, 129, 212, 0.18);
  border-color: rgba(74, 129, 212, 0.12); }
  .btn-soft-blue:hover {
    color: #fff;
    background-color: #4a81d4; }
  .btn-soft-blue:focus, .btn-soft-blue.focus {
    box-shadow: 0 0 0 0.15rem rgba(74, 129, 212, 0.3); }

.card {
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 24px; }

.card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit; }

.card-widgets {
  float: right;
  height: 16px; }
  .card-widgets > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1; }
    .card-widgets > a.collapsed i:before {
      content: "\F0415"; }

.card-title,
.card-header {
  margin-top: 0; }

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.1);
  cursor: progress; }
  .card-disabled .card-portlets-loader {
    background-color: #ffffff;
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 3px;
    margin-left: -12px;
    margin-top: -12px; }

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0; }

.sub-header {
  font-size: 0.875rem;
  margin-bottom: 24px;
  color: #8c98a5; }

.dropdown-menu {
  padding: 0.3rem;
  box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15);
  /*rtl:remove*/
  animation-name: DropDownSlide;
  /*rtl:remove*/
  animation-duration: .3s;
  /*rtl:remove*/
  animation-fill-mode: both;
  top: 100%; }
  .dropdown-menu.show {
    /*rtl:remove*/
    top: 100% !important; }
  .dropdown-menu i {
    display: inline-block; }

.dropdown-menu-end {
  right: 0 !important;
  left: auto !important; }

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important; }

@keyframes DropDownSlide {
  100% {
    transform: translateY(0); }
  0% {
    transform: translateY(15px); } }

@media (min-width: 600px) {
  .dropdown-lg {
    width: 320px; } }

.dropdown-mega {
  position: static !important; }

.dropdown-megamenu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
  background-image: url("../../../images/megamenu-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat; }

.megamenu-list li {
  padding: 5px 20px 5px 25px;
  position: relative; }
  .megamenu-list li a {
    color: #94a0ad; }
    .megamenu-list li a:hover {
      color: #6658dd; }
  .megamenu-list li:before {
    content: "\F0142";
    position: absolute;
    left: 0;
    font-family: "Material Design Icons"; }

html[dir="ltr"] .megamenu-list li:before {
  content: "\F0141"; }

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #94a0ad; }
  .dropdown-icon-item img {
    height: 24px; }
  .dropdown-icon-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dropdown-icon-item:hover {
    background-color: #424e5a; }

@media (min-width: 992px) {
  .dropdown-mega-menu-xl {
    width: 40rem; }
  .dropdown-mega-menu-lg {
    width: 26rem; } }

.form-control,
.form-select {
  box-shadow: none; }

.form-control-light {
  background-color: #3b4550 !important;
  border-color: #3b4550 !important; }

input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus,
.custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important; }

.comment-area-box .form-control {
  border-color: #424e5a;
  border-radius: 0.2rem 0.2rem 0 0; }

.comment-area-box .comment-area-btn {
  background-color: #323a46;
  padding: 10px;
  border: 1px solid #424e5a;
  border-top: none;
  border-radius: 0 0 0.2rem 0.2rem; }

.search-bar .form-control {
  padding-left: 40px;
  padding-right: 20px;
  border-radius: 30px; }

.search-bar span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: calc(1.5em + 0.9rem + 2px);
  left: 13px;
  top: -2px;
  color: #8c98a5; }

.password-eye:before {
  font-family: feather !important;
  content: "\e86a";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px; }

.show-password .password-eye:before {
  content: "\e86e"; }

.modal-title {
  margin-top: 0; }

.modal-full-width {
  width: 95%;
  max-width: none; }

.modal-top {
  margin: 0 auto; }

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #36404a;
  align-content: center;
  transform: translate(25%, 0) !important; }
  .modal-right button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1; }

.modal.show .modal-right, .modal.show .modal-left {
  transform: translate(0, 0) !important; }

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center; }

.modal-colored-header {
  color: #fff;
  border-radius: 0; }
  .modal-colored-header .btn-close {
    color: #fff !important; }

.nav-tabs > li > a, .nav-pills > li > a {
  color: #dee2e6;
  font-weight: 400; }

.nav-pills > a {
  color: #dee2e6;
  font-weight: 400; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: auto; }

.navtab-bg .nav-link {
  background-color: #424e5a;
  margin: 0 5px; }

.nav-bordered {
  border-bottom: 2px solid rgba(206, 222, 239, 0.2) !important; }
  .nav-bordered .nav-item {
    margin-bottom: -1px; }
  .nav-bordered li a {
    border: 0 !important;
    padding: 10px 20px; }
  .nav-bordered a.active {
    border-bottom: 2px solid #6658dd !important; }

.tab-content {
  padding: 20px 0 0 0; }

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 6px;
  border: none; }

.pagination-rounded .page-item:last-child .page-link {
  margin-right: 0px; }

.pagination a {
  line-height: 1.25 !important; }

.popover-header {
  margin-top: 0; }

.progress-sm {
  height: 5px; }

.progress-md {
  height: 8px; }

.progress-lg {
  height: 12px; }

.progress-xl {
  height: 15px; }

/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px; }
  .progress-vertical .progress-bar {
    width: 100%; }
  .progress-vertical.progress-xl {
    width: 15px; }
  .progress-vertical.progress-lg {
    width: 12px; }
  .progress-vertical.progress-md {
    width: 8px; }
  .progress-vertical.progress-sm {
    width: 5px; }

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px; }
  .progress-vertical-bottom .progress-bar {
    width: 100%;
    bottom: 0;
    position: absolute; }
  .progress-vertical-bottom.progress-xl {
    width: 15px; }
  .progress-vertical-bottom.progress-lg {
    width: 12px; }
  .progress-vertical-bottom.progress-md {
    width: 8px; }
  .progress-vertical-bottom.progress-sm {
    width: 5px; }

body {
  padding-right: 0px !important;
  padding-left: 0px !important; }
  body.loading {
    visibility: hidden;
    opacity: 0; }

label {
  font-weight: 400; }

button:focus {
  outline: none; }

th {
  font-weight: 500; }

.table-centered td, .table-centered th {
  vertical-align: middle !important; }

.table-nowrap th, .table-nowrap td {
  white-space: nowrap; }

.table .table-user img {
  height: 30px;
  width: 30px; }

.action-icon {
  color: #cedeef;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px; }
  .action-icon:hover {
    color: #dee2e6; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 10px 0;
  font-family: "Rubik", sans-serif;
  color: #acbfd2; }

.font-11 {
  font-size: 11px !important; }

.font-12 {
  font-size: 12px !important; }

.font-13 {
  font-size: 13px !important; }

.font-14 {
  font-size: 14px !important; }

.font-15 {
  font-size: 15px !important; }

.font-16 {
  font-size: 16px !important; }

.font-17 {
  font-size: 17px !important; }

.font-18 {
  font-size: 18px !important; }

.font-19 {
  font-size: 19px !important; }

.font-20 {
  font-size: 20px !important; }

.font-22 {
  font-size: 22px !important; }

.font-24 {
  font-size: 24px !important; }

.font-26 {
  font-size: 26px !important; }

.font-28 {
  font-size: 28px !important; }

.fw-medium {
  font-weight: 500; }

.fw-semibold {
  font-weight: 600; }
